import React from "react";
import logo from "../assets/images/Logo.png";
import styles from "../screens/Main.module.css";

const Main = () => {
  return (
    <div className={styles.insideMain}>
      <img src={logo} className="App-logo" alt="logo" />
      <span className={styles.subtitle}>
        Não somos apenas desenvolvedores de aplicativos; somos criadores de
        soluções.
      </span>
      <span className={styles.subtitle2}>
        Contato: ctappcore@gmail.com
      </span>
    </div>
  );
};

export default Main;
