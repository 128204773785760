// import logo from "./assets/images/Logo.png";
import "./App.css";
import PoliticaPrivacidade from "./screens/PoliticaPrivacidade";
import QuemSomos from "./screens/QuemSomos";
import Main from "./screens/Main";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import BolsaFamilia from "./screens/BolsaFamilia";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route
            path="/politicaprivacidade"
            element={<PoliticaPrivacidade />}
          />
          <Route path="/quemsomos" element={<QuemSomos />} />
          <Route path="/bolsafamilia" element={<BolsaFamilia />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
