import React from "react";

const QuemSomos = () => {
  return (
    <>
      <h2>Quem somos</h2>
      <p>Em construção</p>
    </>
  );
};

export default QuemSomos;
