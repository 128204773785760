// Navbar.js
import React from "react";
import { Link } from "react-router-dom";
import styles from "./Navbar.module.css";
import Logo from "../assets/images/Logo.png";

const Navbar = () => {
  return (
    <nav className={styles.navbar}>
      <Link to="/" className="App-link">
        <img alt="" src={Logo} width="80px" height="70px" />
      </Link>
      {/* <ul className={styles.navbarList}>
        <li className={styles.navbarItem}>
          <Link to="/" className="App-link">
            Home |
          </Link>
        </li>
        <li className={styles.navbarItem}>
          <Link to="/quemsomos" className="App-link">
            Quem Somos
          </Link>
        </li>
      </ul> */}
    </nav>
  );
};

export default Navbar;
